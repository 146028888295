.App {
  text-align: start;
  width: 100%;
  height: 6505px;
}
/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
/* .topnav a:hover {
  background-color: #ddd;
  color: black;
} */

/* Add an active class to highlight the current page */
.topnav a.active {
  background-color: #adcc49;
  color: white;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  font-size: calc(10px + 2vmin);
  color: white;
}

.social-container {
  position: relative;
  display: inline-block;
  background: white;
  margin-left: 1%;
  top: 50px;
  left: 1100px;
  justify-content: end;
}
.whatsapp {
  width: 34px;
  height: 34px;
  padding: 2px 6px 2px 6px;
  border: 0.5px solid #c8c8c8;
  border-radius: 50%;
  opacity: 0.2px;
}
.whatsapp:hover {
  background-color: #adcc49;
  fill: #ffffff;
}
.whatsapp:hover path {
  background-color: #adcc49;
  fill: #ffffff;
}
.facebook {
  width: 34px;
  height: 34px;
  padding: 3px 4px 3px 4px;
  border: 0.5px solid #c8c8c8;
  border-radius: 50%;
  opacity: 0.2px;
  margin-left: 10px;
}
.facebook:hover {
  background-color: #adcc49;
  fill: #ffffff;
}
.facebook:hover path {
  background-color: #adcc49;
  fill: #ffffff;
}

.linkedin {
  width: 34px;
  height: 34px;
  padding: 0px 5px 4px 5px;
  border: 0.5px solid #c8c8c8;
  border-radius: 50%;
  opacity: 0.2px;
  margin-left: 10px;
}
.linkedin:hover {
  background-color: #adcc49;
  fill: #ffffff;
}
.linkedin:hover path {
  background-color: #adcc49;
  fill: #ffffff;
}
.telegram {
  width: 34px;
  height: 34px;
  padding: 3px 7px 2px 7px;
  border: 0.5px solid #c8c8c8;
  border-radius: 50%;
  opacity: 0.2px;
  margin-left: 10px;
}
.telegram:hover {
  background-color: #adcc49;
}
.telegram:hover path {
  background-color: #adcc49;
  stroke: #ffffff;
}
#navbarContainer {
  width: 80vw;
  height: 73px;
  background-color: #174689;
  margin-left: 16%;
  padding: 2%;
}
.logo {
  margin-left: 20%;
  /* background-color: #fff; */
  width: 231px;
  height: 93px;
  padding: 3px;
}

.button {
  background-color: #adcc49;
  color: #ffffff;
  padding: 2%;
  margin-left: 0%;
  font-size: 15px;
  border: transparent;
}
.button:hover {
  background-color: #cddd6b;
}
.nav-link {
  padding: 1px;
  margin: 5px;
}
.nav-item .nav-link {
  padding-left: 30%;
  color: white;
  font-size: 14px;
  font-family: SourceSansPro600;
}

.nav-item .nav-link:hover {
  background-color: #adcc49;
  border-radius: 5%;
  /* padding: 20px 0px 20px 0px; */
}
.element-phone-icon {
  position: relative;
  width: 24px;
  height: 24px;
}

.element-phone-icon .img {
  position: absolute;
  width: 18px;
  height: 22px;
  width: 24px;
  height: 24px;
  top: 30px;
  left: 40px;
}

.phone-text-wrapper {
  position: relative;
  top: 30px;
  left: 63px;
  color: #000000;
  font-family: SourceSansPro700;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22.5px;
  white-space: nowrap;
  /* font-family: Source Sans Pro;
font-size: 14px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
width: 84px;
height: 22px;
top: 31px;
left: 125px;
position: relative; */
}
.phone .text-wrapper:hover {
  background-color: #adcc49;
}
.email .text-wrapper:hover {
  background-color: #adcc49;
}

.email-text-wrapper {
  position: relative;
  top: 6px;
  left: 243px;
  font-family: SourceSansPro700;
  font-weight: 700;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22.5px;
  white-space: nowrap;
}
.element-email-letter {
  position: relative;
  width: 24px;
  height: 24px;
}

.element-email-letter .icons {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 8px;
  left: 215px;
}
/* .label {
  width: 169px;
  height: 22px;
}
img,
svg {
  vertical-align: text-bottom;
} */
.email {
  font-size: 14px;
  padding-right: 10%;
  margin-right: 20%;
}

.button {
  display: inline-flex;
  height: 73px;
  margin: -30px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: SourceSansPro600;
  gap: 10px;
  padding: 16px 32px;
  position: relative;
  background-color: #adcc49;
}

.frame .get-free {
  position: relative;
  width: fit-content;
  font-family: SourceSansPro600;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

/*Body Section */

.Homerectangle1 {
  top: 140px;
  margin-left:-15px;
}

.HomeHeading1 {
  margin-top: px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  color: #000000;
  font-size: 54px;
  letter-spacing: 0;
  line-height: normal;
  text-align: left;
}

.HomePara {
  font-family: SourceSansPro400;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22.5px;
  text-align: left;
}



.Homerectangle3 {
  width: 100%;
  height: 755px;
  margin-top: 100px;
  background-color: #f7f8f9;
}
.text3 {
  width: 99px;
  height: 17px;
  justify-content: center;
}

.our-services {
  position: relative;
  top: 63px;
  left: 0;
  font-family: SourceSansPro600;
  font-weight: 600;
  color: #000000;
  font-size: 12px;
  letter-spacing: 2.1px;
  line-height: 17.5px;
  white-space: nowrap;
  justify-content: center;
  text-align: center;
}

.vector6 {
  margin-top: 89px;
  left: 0;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.leading-the-charge {
  margin-top: 11px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  color: #000000;
  font-size: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}
.multiSlider{
  padding:20px  25px 30px;
  
}
.multiSlider .slick-arrow.slick-next {
  width: 40px;
  height: 80px;
  size: 10px;
  opacity: 1;
  background-color: #174689;
  border-radius: 80px 0px 0px 80px;
  right: -25px;
}
.multiSlider .slick-arrow.slick-prev {
  width: 40px;
  height: 80px;
  opacity: 1;
  background-color: #174689;
   border-radius: 0px 50px 50px 0px;
  left: -30px;

}
.multiSlider .slick-arrow.slick-prev:hover {
  background-color:#BCBCBC;
}
.multiSlider .slick-arrow.slick-next:hover {
  background-color:#BCBCBC;
}
/* .multiSlider .slick-list {
  margin: 10px;
} */
.auto {
  color: #0000;
  margin-top: 44px;
  margin-left: -153px;
}

.automation {
  width: 289px;
  height: 289px;
  border-top-left-radius: 50px;
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
 }
.automation:hover {
  background-color: #174689;
  -webkit-text-fill-color: #ffffff;
  border-color: #0000;
}
.automation:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  fill: #ffffff;
  stroke: #ffffff;
}


.automation h6 {
  color: #000000;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 43px;
  margin-top: 39px;
  width: 127px;
  height: 56px;
}
.automation p {
  color: #000000;
  width: 223px;
  height: 57px;
  font-family: SourceSansPro400;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 43px;
  margin-top: -20px;
}
.button2 {
  width: 190px;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #000000;
  font-weight: 800;
  font-family: SourceSansPro700;
  display: flex;
  padding: 16px 32px;
  margin-top: 30px;
  text-decoration: none;
  text-align: center;
  margin: auto ;
  justify-content: center;
}
.button2 .explore-services {
  color: #000000;
  font-family: SourceSansPro600;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  width: fit-content;
  text-align: center;
}
.button2:hover {
  transition: 2s;
  background-color: #174689;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
}
.box {
  height: 289px;
  width: 289px;
}

.box .icon1 {
  height: 50px;
  width: 50px;
  left: 0;
  position: relative;
  top: 0;
}
.box .icon2 {
  height: 50px;
  width: 50px;
  left: 0;
  position: relative;
  top: 0;
}
.box .icon3 {
  height: 50px;
  width: 50px;
  left: 0;
  position: relative;
  top: 0;
}
.box .icon4 {
  height: 50px;
  width: 50px;
  left: 0;
  position: relative;
  top: 0;
}


.container-text-center2 {
  width: 100%;
  height: 774px;
}
.container-text-center2.row {
  width: 100%;
  height: 774px;
}

.rectangle38 {
  margin-left: -13px;
}


.why-terraone {
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: left;
  margin-top: 59px;
}
.why-terraoneUnderline {
  width: 605px;
  border: 1px solid;
  border-color: #174689;
}

/* .energyList {
  align-content: start;
  position: relative;
  justify-content: left;
  text-align: left;
  top: 40px;
} */
/* .energyList h6 {
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  font-size: 21px;
} */
/* .energyList p {
  font-family: Source Sans Pro;
  font-weight: 400;
  font-size: 14px;
} */

.Home-why-terraone {
  font-family: AbhayaLibreExtraBold800;
  font-size: 40px;
  font-weight: 800;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 32px;
}
.Home-why-terraonePara {
  font-family: SourceSansPro400;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
#HomeSection4 {
  width: 100%;
  height: 910px;
  background-color: #adcc49;
}

.moreAboutUs {
  background-color: #adcc49;
  color: #ffffff;
  border: 1px solid;
  border-color: #ffffff;
  font-size: 14px;
  top: 95px;
  left: 1190px;
  position: relative;
  align-items: center;
  font-weight: 500;
  padding: 10px;
  width: 150px;
  font-family: SourceSansPro500;
  
}

.moreAboutUs:hover {
  transition: 2s;
  background-color: #174689;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
}

.text6 {
  height: 17px;
  width: 86px;
}

.Home-who-we-are {
  color: #ffffff;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  left: 120px;
  letter-spacing: 2.1px;
  line-height: 17.5px;
  position: relative;
  top: 120px;
  white-space: nowrap;
}

.Homeicon7{
  height: 2px;
  width: 500px;
  margin-left: 120px;
  object-fit: cover;;
  margin-top: 130px;
  width: 500px;
}

.Hometext7 {
  color: #ffffff;
  height: 141px;
  width: 605px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 38px;
  font-weight: 600;
  margin-left: 120px;
  letter-spacing: 0;
  line-height: normal;
  width: 605px;
  text-align: left;
}

.Home-with-over-years {
  color: #ffffff;
  font-family: SourceSansPro400;
  font-size: 14px;
  font-weight: 300;
  margin-left: 120px;
  letter-spacing: 0;
  line-height: 22.5px;
  top: 85px;
  width: 817px;
  text-align: left;
}

.rectangle11 {
  /* width: 750px; */
  height: 480px;
  left: 300px;
  position: relative;
  /* top: 88px; */
}

.rectangle12 {
  height: 413px;
  width: 393px;
  height: 413px;
  left: 950px;
  object-fit: cover;
  position: relative;
  top: -560px;
}
.box2.li {
  text-align: left;
}
.box2 {
  text-align: left;
}
.box3 {
  text-align: left;
}

.box2.text6 {
  height: 28px;
  width: 162px;
  color: #000000;
  font-family: AbhayaLibreExtraBold800;
  font-size: 15px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  top: 20px;
  white-space: nowrap;
  text-align: left;
}
.HomeSection5container {
  width: 100%;
  height: 600px;
}
.text8 {
  height: 17px;
  width: 111px;
}

.Home-our-portfolio{
  color: #000000;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  margin-left: 120px;
  letter-spacing: 2.1px;
  line-height: 17.5px;
  margin-top: 180px;
  white-space: nowrap;
}

.Homevector4 {
  margin-left: 120px;
  width: 211px;
}
.HomeProjects {
  color: #000000;
  font-family: AbhayaLibreExtraBold800;
  font-size: 40px;
  font-weight: 800;
  margin-left: 115px;
  letter-spacing: 2.1px;
  line-height: 17.5px;
  margin-top: 25px;
}

.button3 {
  /* align-items: end; */
  border: 1px;
  border-color: #000000;
  display: inline-flex;
  justify-content: center;
  position: relative;
  left: 1300px;
}
/* .viewMore:hover {
  
  transition: 2s;
  background-color: #174689;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
} */
.button3 .viewMore {
  color: #000000;
  background-color: #ffffff;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  height: 40px;
  position: relative;
  width: 120px;
  border: 1px solid;
  text-align: center;
}
.button3 .viewMore:hover {
  transition: 2s;
  background-color: #174689;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
}

.footerContainer {
  width: 100%;
  height: 560px;
  background-color: #161616;
}
.schedule {
  width: 361px;
  color: white;
  font-size: 40px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 600;
  line-height: 48.25px;
  word-wrap: break-word;
  text-align: left;
  top: 70px;
  left: 0px;
  position: relative;
}

.footerBut {
  width: 237px;
  height: 56px;
  padding: 20px 50px;
  background: #161616;
  border-radius: 29px;
  border: 1px #707070 solid;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 95px;
  left: 0px;
}
.footerButton {
  color: white;
  font-size: 12px;
  font-family: SourceSansPro600;
  font-weight: 600;
  line-height: 16.23px;
  word-wrap: break-word;
  position: relative;
}
.ServicesAboutPortfolioBlogsContact {
  color: #ffffff;
  font-size: 17px;
  font-family: SourceSansPro400;
  font-weight: 400;
  text-decoration: underline;
  line-height: 44.88px;
  position: relative;
  left: 200px;
  text-align: left;
  justify-content: center;
  align-items: left;
  top: 110px;
}

.WhatsappFacebookLinkedinTelegram {
  color: white;
  font-size: 17px;
  font-family: SourceSansPro400;
  font-weight: 400;
  text-decoration: underline;
  line-height: 44.88px;
  word-wrap: break-word;
  position: relative;
  top: 90px;
  right: -90px;
  text-align: left;
  align-items: left;
}
.prinsesseMaries {
  width: 331px;
  opacity: 0.4;
  color: white;
  font-size: 16px;
  font-family: SourceSansPro400;
  font-weight: 400;
  line-height: 24.4px;
  word-wrap: break-word;
  position: relative;
  top: 95px;
  left: 140px;
  text-align: left;
  align-items: center;
}
.mobile {
  color: white;
  font-size: 17px;
  font-family: SourceSansPro400;
  font-weight: 400;
  line-height: 25.92px;
  word-wrap: break-word;
  position: relative;
  top: 110px;
  left: 140px;
  text-align: left;
  align-items: center;
}
.findUs {
  opacity: 0.4;
  color: white;
  font-size: 16px;
  font-family: SourceSansPro400;
  font-weight: 400;
  line-height: 24.4px;
  word-wrap: break-word;
  position: relative;
  top: 150px;
  left: 140px;
  text-align: left;
  align-items: center;
}
.Copenhagen {
  width: 331px;
  color: white;
  font-size: 16px;
  font-family: SourceSansPro400;
  font-weight: 400;
  line-height: 24.4px;
  word-wrap: break-word;
  position: relative;
  top: 170px;
  left: 140px;
  text-align: left;
  align-items: center;
}

.Email {
  opacity: 0.4;
  color: white;
  font-size: 16px;
  font-family: SourceSansPro400;
  font-weight: 400;
  line-height: 24.4px;
  word-wrap: break-word;
  position: relative;
  top: 210px;
  left: 140px;
  text-align: left;
  align-items: center;
}

.InfoTerraoneEngineering {
  width: 331px;
  color: white;
  font-size: 16px;
  font-family: SourceSansPro400;
  font-weight: 400;
  line-height: 24.4px;
  word-wrap: break-word;
  position: relative;
  top: 230px;
  left: 140px;
  text-align: left;
  align-items: center;
}
.Line68 {
  width: 1440px;
  height: 0px;
  opacity: 0.1;
  border: 1px white solid;
  position: relative;
  top: 250px;
  left: 32px;
  text-align: center;
  align-items: center;
}
.AllRightReservedCopyright2023PrivacyPolicy {
  opacity: 0.4;
  color: white;
  font-size: 12px;
  font-family: SourceSansPro400;
  font-weight: 400;
  line-height: 18.3px;
  word-wrap: break-word;
  position: relative;
  top:275px;
  left: -460px;
  text-align: center;
  align-items: center;
}
.ShareYourGoals {
  width: 331px;
  opacity: 0.4;
  color: white;
  font-size: 17px;
  font-family: SourceSansPro200;
  font-weight: 200;
  line-height: 25.92px;
  word-wrap: break-word;
  position: relative;
  top: 80px;
  left: -50px;
  text-align: left;
  align-items: center;
}

.DesignedByTechqilla1 {
  width: 114px;
  color: #7a7a7a;
  font-size: 12px;
  font-family: SourceSansPro400;
  font-weight: 400;
  line-height: 18.3px;
  word-wrap: break-word;
  position: relative;
  top: 255px;
  right: -1055px;
  text-align: right;
  align-items: center;
}
.DesignedByTechqilla2 {
  width: 114px;
  color: white;
  font-size: 12px;
  font-family: SourceSansPro400;
  font-weight: 400;
  line-height: 18.3px;
  position: relative;
  top: 237px;
  left: 1105px;
  text-align: right;
  align-items: center;
}

.container-text-center5 {
  width: 100%;
  height: 830px;
border-radius: 42px;
background-color:#F7F8F9;

}

.portfolio.slick-list {
  gap: 10px;
  padding: 10px;
}
.portfolio.slick-slide img {
  display: block;
  padding: 30px;
  align-items: right;
  justify-content: right;
  margin: 0px;
  gap: 30px;
}
.project1 {
  width: 100px;
  height: 30px;
  border-radius: 50px solid;
  background-color: #adcc49;
  position: relative;
  text-align: center;
}
/* .portfolio .slick-slide.slick-active.slick-current {
  opacity: 1;
}
.portfolio .slick-slide.slick-active {
  opacity: 0.08;
} */

/* .multiSlider {
  width: 1300px;
  top: -15px;
  padding: 0px;
  margin: 0px;
  gap: 10px;
  position: relative;
} */
/* .multiSlider .slick-arrow.slick-prev.slick-disabled {
} */
/* .multiSlider .slick-next:before,
.slick-prev:before {
  /* background-attachment: fixed;
  background-color: #707070;
  height: 80px;
  width: 80px; */

.OurPortfolio {
  color: black;
  font-size: 12px;
  font-family: SourceSansPro600;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 17.48px;
  letter-spacing: 2.1px;
  word-wrap: break-word;
  top: 5px;
  padding: 50px;
  margin: 0px;
  gap: 10px;
  position: relative;
  text-align: center;
}
.Vector3 {
  width: 211px;
  height: 0px;
  border: 1px black solid;
  align-items: center;
  position: relative;
  top: -25px;
  left: 645px;
}

.WhatsSayAbout {
  color: black;
  font-size: 40px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  word-wrap: break-word;
  align-items: center;
  position: relative;
  top: 0px;
  text-align: center;
}

.person1 {
  text-align: left;
  font-family: SourceSansPro400;
  top: 40px;
}
.persondetails {
  font-family: AbhayaLibreExtraBold800;
  font-size: 22px;
  width: 366px;
  height: 55px;
  margin-top: 20px;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.persondetailspara {
  width: 356px;
  height: 168px;
  margin-top: 50px;

  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.personComment {
  width: 220px;
height: 16px;
  font-family: SourceSansPro400;
  margin-left: 60px;
  margin-top: -50px;
}
.personCommentPara{
  width: 131px;
height: 16px;
margin-left: 60px;
font-family: SourceSansPro600;
font-size: 14px;
line-height: 16px;
letter-spacing: 0em;
text-align: left;


}
.persondetailsEllipse7 {
  margin-top: 10px;
}
.Rectangle94 {
  height: 405px;
}
.personDetailsbuttonPrev{
  width: 72px;
height: 51px;
border-radius: 999px;
border: 1px solid #000000;
background-color: #ffffff;
opacity: 0.3px;

}
.personDetailsbuttonNext{
width: 72px;
height: 51px;
margin-left: 30px;
border-radius: 999px;
opacity: 0.3px;
border: 1px solid #000000;

background-color: #ffffff;

}
/* .slick-track {
  width: 2016px;
  opacity: 0.5;
  transform: translate3d(0px, 0px, 0px);
} */
/* .slick-slide.slick-active{
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
.slick-slide.slick-active.slick-current{
  opacity: 0.5;
}
.slick-slide.slick-active[data-index="2"]{
  opacity: 0.5;
} */
.center {
  padding: 0px;
}
/* .center .slick-slide.slick-active{
opacity: 0.3;
} */
.center .slick-slide {
  width: 985px;
  padding: 40px;
  margin-right: -50px;
}
.center .slick-slide.slick-active.slick-cloned {
  opacity: 0.3;
}
.center .slick-slide.slick-active.slick-center.slick-current {
  opacity: 1;
}
.center .slick-slide.slick-cloned {
  opacity: 0.3;
}
.center .slick-slide.slick-active {
  opacity: 0.3;
}

#articleContainer{
  margin-top: 100px;
  margin-bottom: 30px
}

.Articles {
  color: black;
  font-size: 12px;
  font-family: SourceSansPro600;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 17.48px;
  letter-spacing: 2.1px;
  word-wrap: break-word;
  text-align: left;
  position: relative;
  top: 0px;
}
.Vector5 {
  width: 211px;
  height: 0px;
  border: 1px black solid;
  align-items: left;
  position: relative;
  top: 0px;
}
.OurLatestBlogs {
  color: black;
  font-size: 40px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  word-wrap: break-word;
  align-items: left;
  position: relative;
  text-align: left;
  top: 25px;
}
.Rectangle98 {
  width: 499px;
  height: 335px;
  align-items: left;
  justify-content: left;
  position: relative;
  top: 55px;
  left: 0px;
}
.post {
  width: 390px;
  height: 175px;
  background-color: #ffffff;
  align-items: left;
  position: relative;
  top: -135px;
  left: 95px;
}
.post1 {
  width: fit-content;
  align-items: left;
  position: relative;
  justify-content: center;
  top: -80px;
  left: 190px;
}

.February212023 {
  color: #2a2c44;
  font-size: 12px;
  font-family: SourceSansPro700;
  font-weight: 700;
  line-height: 20.4px;
  word-wrap: break-word;
  text-align: left;
  align-items: left;
  position: relative;
  top: 10px;
  left: 20px;
}

.LoremIpsumDolorSitAmetConsectetur {
  color: #030233;
  font-size: 20px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  line-height: 24px;
  word-wrap: break-word;
  text-align: left;
  align-items: left;
  position: relative;
  top: 10px;
  left: 20px;
}

.Pseudo {
  width: 60px;
  height: 1px;
  background: #202056;
  border-radius: 2px;
  text-align: left;
  align-items: left;
  position: relative;
  top: 10px;
  left: 20px;
}
.LoremI {
  width: 347px;
  color: #232323;
  font-size: 15px;
  font-family: SourceSansPro400;
  font-weight: 400;
  line-height: 28.5px;
  word-wrap: break-word;
  text-align: left;
  align-items: left;
  position: relative;
  top: 20px;
  left: 20px;
}
#ArticlesSpacing{
  margin-top: 20px;
}
.Rectangle99 {
  width: 288px;
  height: 183px;
}

.container-grid1 {
  width: 390px;
  height: 175px;
  align-items: left;
  position: relative;
  display: center;
  text-align: left;
  top: -400px;
  left: 700px;
}

#FooterContactUs {
  width: 100%;
  height: 600px;
  background-color: #174689;
}
.Contact {
  opacity: 0.02;
  color: white;
  font-size: 200px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 312.75px;
  word-wrap: break-word;
  align-items: center;
  position: relative;
  text-align: center;
  top: 10px;
}
.ContactUs {
  color: white;
  font-size: 36px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  word-wrap: break-word;
  align-items: left;
  position: relative;
  text-align: center;
  top: -195px;
  left: -205px;
}
#name {
  background-color: #174689;
  font-size: 14px;
  font-family: SourceSansPro400;
  font-weight: 400;
  position: relative;
  text-align: left;
  top: -120px;
  left: 470px;
  border-color: #174689;

  border: none;
  border-bottom: 1px solid;
  border-color: white;
  opacity: 0.5;
  color: #ffffff;
}

.Name {
  color: white;
  font-size: 14px;
  font-family: SourceSansPro400;
  font-weight: 400;
  word-wrap: break-word;
  align-items: left;
  position: relative;
  text-align: center;
  top: -100px;
  left: -265px;
}
.PhoneNumber {
  color: white;
  font-size: 14px;
  font-family: SourceSansPro400;
  font-weight: 400;
  word-wrap: break-word;
  align-items: left;
  position: relative;
  text-align: center;
  top: -170px;
  left: 0px;
}
#phone {
  background-color: #174689;
  font-size: 14px;
  font-family: SourceSansPro400;
  font-weight: 400;
  position: relative;
  text-align: left;
  top: -190px;
  left: 708px;
  border: none;
  border-bottom: 1px solid;
  border-color: white;
  opacity: 0.5;
  color: #ffffff;
}
.EmailAddress {
  color: white;
  font-size: 14px;
  font-family: SourceSansPro400;
  font-weight: 400;
  word-wrap: break-word;
  align-items: left;
  position: relative;
  text-align: right;
  top: -240px;
  left: -490px;
}
#email {
  background-color: #174689;
  border-color: #174689;
  font-size: 14px;
  font-family: SourceSansPro200;
  font-weight: 100;
  position: relative;
  text-align: left;
  top: -260px;
  left: 930px;

  border: none;
  border-bottom: 1px solid;
  border-color: white;
  opacity: 0.5;
  color: #ffffff;
}
.Message {
  color: white;
  font-size: 14px;
  font-family: SourceSansPro400;
  font-weight: 400;
  word-wrap: break-word;
  align-items: left;
  position: relative;
  text-align: center;
  top: -175px;
  left: -255px;
}
#message {
  background-color: #174689;
  width: 629px;
  font-size: 14px;
  font-family: SourceSansPro400;
  font-weight: 400;
  word-wrap: break-word;
  align-items: left;
  position: relative;
  text-align: left;
  top: -180px;
  left: 470px;

  border: none;
  border-bottom: 1px solid;
  border-color: white;
  opacity: 0.5;
  color: #ffffff;
}
.submit {
  background-color: #174689;
  color: #ffffff;
  border-color: #ffffff;
  width: 120px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: SourceSansPro600;
  align-items: left;
  position: relative;
  text-align: center;
  top: -145px;
  left: 980px;
}
.submit:hover {
  transition: 2s;
  background-color: #adcc49;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
}


.GetAFreeConsultation {
  width: 468px;
  height: 200px;
  padding: 45px;
  text-align: center;
  color: white;
  display: grid;
  font-size: 36px;
  background-color: #adcc49;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  word-wrap: break-word;
  align-items: center;
  position: relative;
  text-align: center;
  top: 0px;
  left: 0px;
}
.container-text-left7 {
  width: 100%;
  height: 200px;
  background-color: #000000;
  position: relative;
  justify-content: center;
  align-items: center;
}
.Submit1 {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: center;
  align-items: center;
  width: 156px;
  height: 62px;
  color: white;
  background: #adcc49;
  font-size: 14px;
  font-family: SourceSansPro500;
  font-weight: 500;
  text-transform: uppercase;
  word-wrap: break-word;
  align-items: center;
  position: relative;
  text-align: center;
  top: -130px;
  left: 630px;
}
.Submit1:hover {
  transition: 2s;
  background-color: #174689;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
}
#yourName {
  width: 280px;
  height: 52px;
  color: white;
  background: #000000;
  font-size: 14px;
  font-family: SourceSansPro600;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
  align-items: center;
  position: relative;
  text-align: left;
  top: -130px;
  left: 550px;
  padding: 10px;
  border: 1px solid;
}
#emailAddress {
  width: 330px;
  height: 52px;
  color: white;
  background: #000000;
  font-size: 14px;
  font-family: SourceSansPro600;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
  align-items: center;
  position: relative;
  text-align: left;
  top: -130px;
  left: 585px;
  padding: 10px;
  border: 1px solid;
}

.autoicon {
  width: 45.68px;
  height: 44.98px;
  left: 115.1px;
  top: -290px;
  position: absolute;
}

.Rectangle32:hover {
  transform: translateZ(-3px);
  transition: 1s;
  background-color: #174689;
  border-radius: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), inset 0 0;
  outline: #adcc49 solid 5px;
}

.Rectangle33:hover {
  transform: translateZ(-3px);
  transition: 1s;
  background-color: #174689;
  border-radius: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), inset 0 0;
  outline: #adcc49 solid 5px;
}
.Rectangle34:hover {
  transform: translateZ(-3px);
  transition: 1s;
  background-color: #174689;
  border-radius: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), inset 0 0;
  outline: #adcc49 solid 5px;
}
.Rectangle36:hover {
  transform: translateZ(-3px);
  transition: 1s;
  background-color: #174689;
  border-radius: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), inset 0 0;
  outline: #adcc49 solid 5px;
}
.col-md-9 {
  flex: 0 0 auto;
  width: 75%;
  left: 10px;
  position: relative;
}
.textContainer {
  left: 30px;
  position: relative;
}
element.style {
  border: none;
  border-bottom: 1px solid;
}
.greatButton {
  color: white;
  background-color: #adcc49;
  width: 114px;
  font-size: 16px;
  font-family: Test Söhne;
  font-weight: 400;
  line-height: 31.35px;
  word-wrap: break-word;
  border-radius: 50px;
  border: #adcc49;
}

.clientsAbout {
  top: 10px;
}
.clientsAbout.slider {
  padding-left: 10px;
  margin: 0px;
  position: relative;
  align-items: center;
}

/* .clientsAbout .slick-slide.slick-active.slick-current {
  opacity: 1;
}
.clientsAbout .slick-slide.slick-active {
  opacity: 0.08;
}
.container-text-right {
  padding: 10px;
  gap: 10px;
  position: relative;
} */
/* .clientsAbout .slick-slide.slick-active.slick-current[data-index="1"]{
    opacity: 0.1;
  
    } */
/* .HomeEllipse19 {
} */
.HomeEnergyEfficient {
  color: #000000;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.HomeEnergyEfficientPara {
  font-family: SourceSansPro400;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 7px;
}
.HomeEllipse20 {
}
.Long-Term-Relationship {
  color: #000000;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.Long-Term-RelationshipDesc {
  font-family: SourceSansPro400;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.HomeEllipse21 {
}
.Day-To-Day-Operations {
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.Day-To-Day-OperationsDesc {
  font-family: SourceSansPro400;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 30px;
}
.HomeEllipse22 {
}
.ReduceEnergyConsumption {
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.ReduceEnergyConsumptionDesc {
  font-family: SourceSansPro400;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.allArticles {
  width: 147px;
  height: 50px;
  top: 5784px;
  left: 449px;
  padding: 16px, 32px, 16px, 32px;
  border: 1px solid;
  gap: 10px;
  background-color: #ffffff;
  position: relative;
  top: -50px;
  left: 370px;
  font-family: SourceSansPro600;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}
.allArticles:hover {
  transition: 2s;
  background-color: #174689;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
}

.rowSection {
  /* position: relative; */
}
.clients {
  background-color: #174689;
  color: #ffffff;
  width: 123px;
  height: 83px;
  text-align: center;
  padding: 10px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  font-size: 18px;
  margin-top: -420px;
  margin-left: 592px;
}
.projects {
  background-color: #174689;
  color: #ffffff;
  width: 123px;
  height: 83px;
  text-align: center;
  padding: 10px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  font-size: 18px;
  margin-top: -305px;
  margin-left: 592px;
}
.countryServed {
  background-color: #174689;
  color: #ffffff;
  width: 123px;
  height: 83px;
  text-align: center;
  padding: 3px;
  font-family: AbhayaLibreExtraBold800;
  font-weight: 800;
  font-size: 18px;
  margin-top: -190px;
  margin-left: 592px;
}

.portfolio .slick-track {
  width: 100%px;
}
.portfolio .slick-list {
  top: 45px;
  gap: 10px;
}
.imageSlider {
  width: 1410px;
  margin-left: 50px;
  gap: 0px;
  top: 50px;  
  padding-left: 10px;
}



.portfolio .slick-slide img {
  align-items: right;
  display: block;
  gap: 10px;
  justify-content: right;
  margin: 10px;
  padding: 35px;
  margin-left: -30px;
}
/* .portfolio .slick-slide.slick-active.slick-current {
  width: 605px;
  height: 375px;
} */
/* .portfolio .slick-slide.slick-active {
  width: 303px;
} */







.arrow1 {
  color: #ffffff;
  width: 46px;
  border: 1px;
  margin-left: 202px;
  margin-top: -70px;
}

.bannerImage {
  width: 100%;
  height: 376px;
  margin-top: -17px;
}
.bannerHeading {
  top: 287px;
  margin-left: 100px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 56px;
  font-weight: 800;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  position: absolute;
}
.bannerPara {
        /* width: 456px; */
    /* height: 46px; */
    margin-left: 100px;
    font-family: SourceSansPro400;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    position: absolute;
    top: 353px;
}
#aboutContainer{  
  margin-top: 120px;
}
.Rectangle106 {
  width: 393px;
  height: 434px;
  /* margin-top: 282px; */
  /* margin-left: 100px; */
  background-color: #174689;
  position: absolute;
}
.aboutPara {
    /* width: 45px; */
    /* height: 17px; */
    margin-top: 65px;
    margin-left: 61px;
    font-family: SourceSansPro600;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.175em;
    text-align: left;
    color: #ffffff;
    position: relative;
}
.aboutUnder {
  width: 129px;
  margin-left: 61px;
  border: 1px solid;
  border-color: #ffffff;
}
.aboutDesc {
  width: 279px;
  height: 264px;
  margin-top: 9px;
  margin-left: 61px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 56px;
  font-weight: 800;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.Rectangle107 {
  width: 136px;
  height: 136px;
  margin-left: 318px;

}
.descriptionIntegration {
     /* width: 456px; */
    /* height: 55px; */
    margin-top: 17px;
    /* margin-left: 629px; */
    font-family: AbhayaLibreExtraBold800;
    font-size: 28px;
    font-weight: 800;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}
.descriptionTerra {
        /* width: 711px; */
    /* height: 224px; */
    margin-top: 32px;
    /* margin-left: 629px; */
    font-family: SourceSansPro400;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}
.Rectangle42 {
  width: 100%;
  height: 583px;
  background-color: #174689;
  margin-top: 266px;
  
}
.Our-Mission {
  width: 214px;
  height: 46px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 40px;
  font-weight: 800;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-top: -457px;
  margin-left: 100px;
}
.Our-MissionDescp {
  width: 692px;
  height: 196px;
  margin-top: 18px;
  margin-left: 100px;
  font-family: SourceSansPro400;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.ourWorkflow {
  width: 135px;
  height: 17px;
  top: 1928px;
  left: 665px;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  position: absolute;
  text-transform: uppercase;
}
.ourWorkflowUnder {
  width: 155px;
  top: 1964px;
  left: 656px;
  border: 1px solid;
  border-color: #174689;
  position: absolute;
}
.fromStrategy {
  width: 580px;
  height: 47px;
  top: 1985px;
  left: 440px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 40px;
  font-weight: 800;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
}
.image3 {
  width: 104px;
  height: 104px;
  top: 2129px;
  left: 233px;
  position: absolute;
}
.consultation {
  width: 153px;
  height: 32px;
  top: 2262px;
  left: 202px;

  font-family: AbhayaLibreExtraBold800;
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
}
.consultationDesc {
  width: 232px;
  height: 138px;
  top: 2313px;
  left: 162px;

  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
}
.image4 {
  width: 104px;
  height: 104px;
  top: 2129px;
  left: 523px;

  position: absolute;
}
.design {
  width: 80px;
  height: 32px;
  top: 2262px;
  left: 534px;

  font-family: AbhayaLibreExtraBold800;
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
}
.designDesc {
  width: 232px;
  height: 161px;
  top: 2313px;
  left: 458px;

  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
}
.image5 {
  width: 104px;
  height: 104px;
  top: 2129px;
  left: 812px;

  position: absolute;
}
.installation {
  width: 138px;
  height: 32px;
  top: 2262px;
  left: 794px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
}
.installationDesc {
  width: 232px;
  height: 207px;
  top: 2313px;
  left: 748px;

  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
}
.image6 {
  width: 104px;
  height: 104px;
  top: 2129px;
  left: 1102px;
  position: absolute;
}
.ongoingSupport {
  width: 210px;
  height: 32px;
  top: 2262px;
  left: 1052px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
}
.ongoingSupportDesc {
  width: 238px;
  height: 230px;
  top: 2313px;
  left: 1035px;

  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
}
#rectDwayne {
  width: 100%;
  height: 868px;
  top: 280px;

  background-color: #adcc49;
  position: relative;
  border-radius: 1000px 1000px 0px 0px;
}
.Meetthefounder {
  /* width: 140px;
  height: 17px; */
  top: 107px;
  /* left: 665px; */
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  position: relative;
  color: #ffffff;
  text-transform: uppercase;
}
.MeetthefounderUnder {
  position: relative;
  width: 129px;
  top: 130px;
  left: 670px;
  border: 1px solid;
  border-color: #ffffff;
}
.founderDedication {
     /* width: 876px; */
    /* height: 47px; */
    top: 157px;
    /* left: 310px; */
    font-family: AbhayaLibreExtraBold800;
    font-size: 40px;
    font-weight: 800;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    color: #ffffff;
}
.founderDedicationDesc {
      /* width: 665px; */
    /* height: 56px; */
    top: 177px;
    /* left: 410px; */
    font-family: SourceSansPro400;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    color: #ffffff;
}
.dwaynePhoto {
  width: 303px;
  height: 401px;
  top: 200px;
  left: 589px;
  border-radius: 160px;
  position: relative;
}
.founderName {
  position: relative;
  color: #ffffff;
  width: 350px;
  height: 47px;
  top: 230px;
  left: 573px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 40px;
  font-weight: 800;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
}
.founderPost {
  position: relative;
  color: #ffffff;
  width: 190px;
  height: 28px;
  top: 235px;
  left: 644px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.aboutSlider {
  width: 1240px;
  margin: 10px;
  top: -300px;
  position: relative;
  padding: 10px;
  margin-left: 80px;
}
.aboutSlider .slick-arrow.slick-prev {
  position: absolute;
  /* clip-path: ; */
  top: 600px;
  left: 300px;
  background-color: #fff;
  border-radius: 100%;
  content: "<--";
  height: 80px;
  opacity: 0.2;
  position: absolute;
  scale: 0.6;
  transition: 0.3s linear;
  width: 80px;
  z-index: 1;
}
.aboutSlider .slick-prev:before {
  content: "";
  background-repeat: no-repeat;
  background-size: your-value;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #000000;
}

.aboutSlider .slick-arrow.slick-next {
  position: absolute;
  /* background:"Teraone/Arrow 7.svg" ; */
  top: 600px;
  left: 400px;
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #0000 !important;
  content: "";
  height: 80px;
  opacity: 0.2;
  position: absolute;
  scale: 0.6;
  transition: 0.3s linear;
  width: 80px;
  z-index: 1;
}

/* .Rectangle108{
  width: 146px;
height: 179px;
top: 1578px;
left: 171px;

} */
/* .Rectangle109{
  width: 433px;
height: 562px;
top: 1145px;
left: 841px;

} */
/* .container-image {
  position: absolute;
  top: 0px;
  height: 300px;
} */
.gapUnderCeo {
  position: relative;
  top: 150px;
}
.rectDwayne1 {
  width: 100%;
  height: 280px;
  top: 10px;
  position: relative;
}
.buttonSlickPrev {
  position: absolute;
  width: 46px;
  top: 367px;
  left: 412px;
  border: 1px;
  fill: #ffffff;
  background-color: transparent;
}
.buttonSlickNext {
  position: absolute;
  position: absolute;
  width: 46px;
  top: 367px;
  left: 480px;
  border: 1px;
  background-color: transparent;
}
.ServicesRectangle105 {
  width: 100%;
  height: 376px;
  margin-top: -17px;
}
.ServicesServ {
  position: absolute;
  top: 287px;
  left: 100px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 56px;
  font-weight: 800;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.ServicesServPara {
  position: absolute;
  /* width: 483px; */
  /* height: 46px; */
  top: 353px;
  left: 100px;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.systemIntegrationServices {
    /* width: 260px; */
    /* height: 17px; */
    /* margin-left: 610px; */
    margin-top: 100px;
    font-family: SourceSansPro600;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.175em;
    text-align: center;
    text-transform: uppercase;
}
.systemIntegrationServicesUnder {
  width: 153px;
  margin-left: 656px;
margin-top: 19px;
  border: 1px solid;
  border-color: #174689;
}
.systemIntegrationServicesHead {
    /* width: 825px; */
    /* height: 94px; */
    /* margin-left: 353px; */
    margin-top: 21px;
    font-family: AbhayaLibreExtraBold800;
    font-size: 40px;
    font-weight: 800;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
}
#systemIntegrationSection1{
  margin-top: 63px;
}
.systemIntegrationServices1 {
  width: 393px;
  height: 457px;
  border-radius: 8px;
  border: 0.5px solid #d4d4d4;
}
.systemIntegrationServices1:hover {
  /* transition: 2s; */
  background-color: #adcc49;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
  fill: #ffffff;
}

.systemIntegrationServices1:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  fill: #ffffff;
}
#automation_robot_icon_1 {
  width: 56px;
  height: 55px;
  margin-top: 26px;
  margin-left: 20px;
}
.Building_Automation {
  width: 127px;
  height: 56px;
  margin-top: 20px;
  margin-left: 20px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #174689;
}
.Building_Automation_para {
  width: 351px;
  height: 253px;
  margin-top: 10px;
  margin-left: 20px;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  opacity: 0.6;
  letter-spacing: 0em;
  text-align: left;
  /* color: #174689; */
}
#ui_essential_app_energy_bolt_icon_1 {
  position: relative;
  width: 56px;
  height: 55px;
  top: 26px;
  left: 20px;
}
.systemIntegrationServices2 {
  width: 393px;
  height: 457px;
  border-radius: 8px;
  border: 0.5px solid #d4d4d4;
}
.systemIntegrationServices2:hover {
  /* transition: 2s; */
  background-color: #adcc49;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
  fill: #ffffff;
  stroke: #ffffff;
}
.systemIntegrationServices2:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  /* fill: #ffffff; */
  stroke: #ffffff;
}
.Energy_Efficiency {
  width: 100px;
  height: 56px;
  margin-top: 34px;
  margin-left: 20px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #174689;
}
.Energy_Efficiency_para {
  width: 353px;
  height: 253px;
  margin-top: 10px;
  margin-left: 20px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #174689; */
}
.systemIntegrationServices3 {
  width: 393px;
  height: 457px;
  border-radius: 8px;
  left: 947px;
  border-radius: 8px;
  border: 0.5px solid #d4d4d4;
}
.systemIntegrationServices3:hover {
  /* transition: 2s; */
  background-color: #adcc49;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
  fill: #ffffff;
}
.systemIntegrationServices3:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  fill: #ffffff;
}
.systemIntegrationServices3:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  fill: #ffffff;
}
#problem_process_solution_solve_trouble_icon_1 {
  width: 56px;
  height: 55px;
  margin-top: 26px;
  margin-left: 20px;
}
.Process_Automation {
  width: 121px;
  height: 56px;
  margin-top: 20px;
  margin-left: 20px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #174689;
}
.Process_Automation_para {
  width: 348px;
  height: 207px;
  margin-top: 10px;
  margin-left: 20px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #174689; */
}
#systemIntegrationSection2{
  margin-top: 25px;
}
.systemIntegrationServices4 {
  width: 393px;
  height: 457px;
  border-radius: 8px;
  border: 0.5px solid #d4d4d4;
}
.systemIntegrationServices4:hover {
  /* transition: 2s; */
  background-color: #adcc49;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
}
.systemIntegrationServices4:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  /* fill: #ffffff; */
  stroke:  #ffffff;
}
#g2737 {
  width: 56px;
  height: 55px;
  margin-left: -300px;
  margin-top: 31px;
}
.Network_Design {
  width: 121px;
  height: 56px;
  margin-top: 24px;
  margin-left: 18px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #174689;
}
.Network_Design_para {
  width: 348px;
  height: 207px;
  margin-top: 10px;
  margin-left: 18px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #174689; */
}
.systemIntegrationServices5 {
  width: 393px;
  height: 457px;
  border-radius: 8px;
  border: 0.5px solid #d4d4d4;
}

.systemIntegrationServices5:hover {
  /* transition: 2s; */
  background-color: #adcc49;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
  fill: #ffffff;
}
.systemIntegrationServices5:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  fill: #ffffff;
  stroke: #ffffff;
}
#dairy_factory_industry_manufacture_processing_icon {
  
  width: 56px;
  height: 55px;
  margin-top: 31px;
  margin-left: -280px;
}
.Manufacturing_Execution_System {
  width: 270px;
  height: 56px;
  margin-top: 24px;
  margin-left: 20px;

  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #174689;
}
.Manufacturing_Execution_System_para {
  width: 358px;
  height: 207px;
  margin-top: 10px;
  margin-left: 20px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #174689; */
}
.systemIntegrationServices6 {
  width: 393px;
  height: 457px;
  border-radius: 8px;
  left: 947px;
  border-radius: 8px;
  border: 0.5px solid #d4d4d4;
}
.systemIntegrationServices6:hover {
  /* transition: 2s; */
  background-color: #adcc49;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
  fill: #ffffff;
}
.systemIntegrationServices6:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  fill: #ffffff;
}
#computer_desktop_monitor_computer_screen_icon {
  width: 56px;
  height: 55px;
  margin-left: -300px;
  margin-top: 31px;
}
.Computer_Maintenance {
  width: 250px;
  height: 56px;
  margin-left: 20px;
  margin-top: 24px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #174689;
}
.Computer_Maintenance_para {
  width: 348px;
  height: 207px;
  margin-top: 10px;
  margin-left: 20px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #174689; */
}

#systemIntegrationSection3{
  margin-top: 30px;
}
.systemIntegrationServices7 {
  width: 393px;
  height: 457px;
  border-radius: 8px;
  border: 0.5px solid #d4d4d4;
}
.systemIntegrationServices7:hover {
  /* transition: 2s; */
  background-color: #adcc49;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
  fill: #ffffff;
}
.systemIntegrationServices7:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  /* fill: #ffffff; */
  stroke: #ffffff;
}
#earth_globe_internet_map_network_icon {
  position: relative;
  width: 56px;
  height: 55px;
  top: 26px;
  left: 20px;
}
.Industrial_Internet_of_Things {
  position: relative;
  width: 201px;
  height: 56px;
  top: 34px;
  left: 26px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #174689;
}
.Industrial_Internet_of_Things_para {
  position: relative;
  width: 348px;
  height: 207px;
  top: 49px;
  left: 20px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #174689; */
}
.systemIntegrationServices8 {
  width: 393px;
  height: 457px;
  border-radius: 8px;
  border: 0.5px solid #d4d4d4;
}
.systemIntegrationServices8:hover {
  /* transition: 2s; */
  background-color: #adcc49;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
  fill: #ffffff;
}
.systemIntegrationServices8:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  fill: #ffffff;
  stroke: #ffffff;
}
#config_configure_control_management_options_icon {
  position: relative;
  width: 56px;
  height: 55px;
  top: 26px;
  left: 20px;
}
.System_Validation {
  position: relative;
  width: 201px;
  height: 56px;
  top: 34px;
  left: 26px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #174689;
}
.System_Validation_para {
  position: relative;
  width: 348px;
  height: 207px;
  top: 49px;
  left: 20px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #174689; */
}
.systemIntegrationServices9 {
  width: 393px;
  height: 457px;
  border-radius: 8px;
  border: 0.5px solid #d4d4d4;
}
.systemIntegrationServices9:hover {
  /* transition: 2s; */
  background-color: #adcc49;
  border-radius: 2%;
  -webkit-text-fill-color: #ffffff;
  fill: #ffffff;
}
.systemIntegrationServices9:hover svg path {
  /* transition: 2s; */
  border-radius: 2%;
  fill: #ffffff;
  stroke: #ffffff;
}
#consulting_leadership_online_process_support_icon {
  position: relative;
  width: 56px;
  height: 55px;
  top: 26px;
  left: 20px;
}
.Consulting_Services {
  position: relative;
  width: 201px;
  height: 56px;
  top: 34px;
  left: 26px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #174689;
}
.Consulting_Services_para {
  position: relative;
  width: 348px;
  height: 207px;
  top: 49px;
  left: 20px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #174689; */
}
#servicesRectangale119 {
  position: relative;
  background-color: #adcc49;
  width: 1440px;
  height: 710px;
  top: 80px;
  /* left: 34px; */
  border-radius: 46px;
}
.services_our_workflow {
  position: relative;
  width: 150px;
  height: 17px;
  top: 70px;
  left: 669px;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.services_our_workflow_underline {
  position: relative;
  width: 129px;
  top: 89px;
  left: 680px;
  border: 1px solid;
  justify-items: center;
  color: #ffffff;
}
.services_from_strategy_to_implementation {
  position: relative;
  width: 576px;
  height: 47px;
  top: 117px;
  left: 444px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 40px;
  font-weight: 800;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.services_image3 {
  width: 104px;
  height: 104px;
  margin-top: 201px;
}
.services_consultation {
  width: 153px;
  height: 32px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
  margin-top: 10px;
  margin-left: 72px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.services_consultationDesc {
  width: 232px;
  height: 138px;
  top: 2910px;
  left: 163px;
  font-family: SourceSansPro400;
  font-size: 16px;

  margin-top: 10px;
  margin-left: 32px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.services_image4 {
  width: 104px;
  height: 104px;
  margin-top: 201px;
}
.services_design {
  width: 153px;
  height: 32px;
  margin: 10px;
  margin-left: 72px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.services_designDesc {
  width: 232px;
  height: 138px;
  margin: 10px;
  margin-left: 32px;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.services_image5 {
  width: 104px;
  height: 104px;
  margin-top: 201px;
}
.services_installation {
  width: 153px;
  height: 32px;
  margin-top: 10px;
  margin-left: 72px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.services_installationDesc {
  width: 232px;
  height: 138px;
  margin-top: 10px;
  margin-left: 32px;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.services_image6 {
  width: 104px;
  height: 104px;
  margin-top: 201px;
}
.services_ongoingSupport {
  width: 210px;
  height: 32px;
  margin-top: 10px;
  margin-left: 72px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.services_ongoingSupportDesc {
  width: 236px;
  height: 138px;
  margin-top: 10px;
  margin-left: 57px;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.services_image {
  position: relative;
  height: 150px;
}
.blogsBanner {
  width: 100%;
  height: 376px;
  position: relative;
  top: -17px;
}
.blogsBannerHeading {
  position: absolute;
  width: 233px;
  height: 66px;
  top: 287px;
  left: 100px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 56px;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.blogsBannerPara {
  position: absolute;
  width: 456px;
  height: 46px;
  top: 353px;
  left: 100px;
  font-family: SourceSansPro400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.Types_of_energy_management  {
  /* width: 292px; */
  /* height: 28px; */
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  /* margin-left: 363px; */
  margin-top: 100px;
}
.Types_of_energy_management_para {
    /* width: 94px; */
    /* height: 17px; */
    font-family: SourceSansPro600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.175em;
    text-align: center;
    /* margin-left: 467px; */
    /* margin-top: 10px*/
}

.blogsBanner2 {
  margin-top: 18px;
}
.emailSubscription {
    /* position: absolute; */
    /* top: 560px; */
    /* width: 393px; */
    /* height: 242px; */
    border: 0.5px solid #c8c8c8;
    /* margin-left: 987px; */
    margin-top: 100px;
}
.subscribe_to_our_newsletter {
  /* width: 238px; */
  /* height: 17px; */
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-transform: uppercase;
  margin-top: 40px;
  /* margin-left: 83px; */
}
.subscribe_to_our_newsletter_para {
  width: 38px;
  border: 1px solid #174689;
  margin-top: 12px;
  margin-left: 178px;
  /* text-align: center; */
}
#blogsEmail {
  width: 318px;
  height: 42px;
  border: 1px solid #c8c8c8;
  color: #000000;
  opacity: 0.6px;
  font-family: SourceSansPro400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  /* margin-left: 38px; */
  /* margin-top: 15px; */
}
.blogsSubscribe {
  width: 320px;
  height: 45px;
  padding: 16px, 0px, 16px, 0px;
  gap: 10px;
  font-family: SourceSansPro600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #adcc49;
  color: #ffffff;
  text-transform: uppercase;
  /* margin-left: 38px; */
  margin-top: 18px;
  border: none;
  margin-bottom: 53px;
}
.socialSection {
  /* position: absolute; */
  /* width: 393px; */
  /* height: 113px; */
  /* top: 903px; */
  /* margin-left: 987px; */
  margin-top: 30px;
  border: 0.5px solid #c8c8c8;
}
.follow_us_on {
  /* width: 110px; */
  /* height: 17px; */
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  text-transform: uppercase;
  /* margin-left: 146px; */
  margin-top: 19px;
}
.follow_us_on_para {
  width: 38px;
  border: 1px solid #174689;
  margin-left: 178px;
  margin-top: 12px;
}
.blogsWhatsapp {
  width: 34px;
  height: 34px;
  padding: 3px 4px 3px 4px;
  border: 0.5px solid #c8c8c8;
  border-radius: 50%;
  opacity: 0.2px;
  /* margin-left: 110px; */
  margin-bottom: 20px;
}
.blogsWhatsapp:hover {
  background-color: #adcc49;
  fill: #ffffff;
}
.blogsWhatsapp:hover path {
  background-color: #adcc49;
  fill: #ffffff;
}
.blogsFacebook {
  width: 34px;
  height: 34px;
  padding: 3px 4px 3px 4px;
  border: 0.5px solid #c8c8c8;
  border-radius: 50%;
  opacity: 0.2px;
  margin-bottom: 20px;
  margin-left: 11px;
}
.blogsFacebook:hover {
  background-color: #adcc49;
  fill: #ffffff;
}
.blogsFacebook:hover path {
  background-color: #adcc49;
  fill: #ffffff;
}
.blogsLinkedIn {
  width: 34px;
  height: 34px;
  padding: 3px 4px 3px 4px;
  border: 0.5px solid #c8c8c8;
  border-radius: 50%;
  opacity: 0.2px;
  margin-left: 15px;
  margin-bottom: 20px;
}
.blogsLinkedIn:hover {
  background-color: #adcc49;
  fill: #ffffff;
}
.blogsLinkedIn:hover path {
  background-color: #adcc49;
  fill: #ffffff;
}
.blogsTelegram {
  width: 34px;
  height: 34px;
  padding: 3px 4px 3px 4px;
  border: 0.5px solid #c8c8c8;
  border-radius: 50%;
  opacity: 0.2px;
  margin-left: 11px;
  margin-bottom: 20px;
}
.blogsTelegram:hover {
  background-color: #adcc49;
  /* fill: #ffffff; */
}
.blogsTelegram:hover path {
  background-color: #adcc49;
  /* fill: #ffffff; */
  stroke: #ffffff;
}

.energy_management_in_agriculture {
    /* width: 385px; */
    /* height: 28px; */
    margin-top: 32px;
    margin-left: 15px;
    font-family: AbhayaLibreExtraBold800;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}
.energy_management_in_agriculture_para {
    margin-top: 18px;
  margin-left: 15px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.latest_post {   
   /* position: absolute; */
    /* width: 393px; */
    /* height: 391px; */
    /* margin-left: 987px; */
    margin-top: 30px;
    border: 0.5px solid #c8c8c8;
}
.Latestpost {
  /* width: 100px; */
  /* height: 17px; */
  margin-top: 20px;
  /* margin-left: 152px; */
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  text-transform: uppercase;
}
.bloglatestpostimage1 {
  margin-top: 28px;
  margin-left: 10px;
}
.Right_collaboration_to_save_earth {
  width: 239px;
  height: 46px;
  margin-top: 28px;
  margin-left: 65px;
  font-family: SourceSansPro400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.Right_collaboration_to_save_earth_para {
  width: 94px;
  height: 17px;
  margin-top: 16px;
  margin-left: 65px;
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
}
.bloglatestpostimage2 {
  margin-top: 10px;
  margin-left: 10px;
}
.Powering_the_Energy_Generation {
  width: 239px;
  height: 46px;
  margin-top: 10px;
  margin-left: 32.5px;
  font-family: SourceSansPro400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.Powering_the_Energy_Generation_para {
  width: 94px;
  height: 17px;
  margin-top: 10px;
  margin-left: 32.5px;
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
}
.bloglatestpostimage3 {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 30px;
}
.How_efficient_is_eco_system_world {
  width: 239px;
  height: 46px;
  margin-top: 20px;
  margin-left: 32.5px;
  font-family: SourceSansPro400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.How_efficient_is_eco_system_world_para {
  width: 94px;
  height: 17px;
  margin-top: 10px;
  margin-left: 32.5px;
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
}
.Energy_management_in_smart_Factory {
    /* width: 416px; */
    /* height: 28px; */
    font-family: AbhayaLibreExtraBold800;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    /* margin-top: 94px; */
    margin-left: 15px;
}
.Energy_management_in_smart_Factory_para {
    /* width: 817px; */
    /* height: 276px; */
    /* margin-top: 18px; */
    margin-left: 15px;
    opacity: 0.6;
    font-family: SourceSansPro400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}
.Energy_Management_in_buildings {
    /* width: 366px; */
    /* height: 28px; */
    /* margin-top: 44px; */
    margin-left: 15px;
    font-family: AbhayaLibreExtraBold800;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}
.Energy_Management_in_buildings_para {
    /* width: 817px; */
    /* height: 184px; */
    /* margin-top: 17px; */
    margin-left: 15px;
    opacity: 0.6;
    font-family: SourceSansPro400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}
.Energy_management_in_homes {
    /* width: 336px; */
    /* height: 28px; */
    /* margin-top: 65px; */
    margin-left: 15px;
    font-family: AbhayaLibreExtraBold800;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}
.Energy_management_in_homes_para {
     /* width: 817px; */
    /* height: 184px; */
    /* margin-top: 17px; */
    margin-left: 15px;
    opacity: 0.6;
    font-family: SourceSansPro400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}
.blogsRelatedTags {
    /* width: 250px; */
    /* height: 23px; */
    /* margin-top: 264px; */
    /* margin-left: 100px; */
    font-family: AbhayaLibreExtraBold800;
    font-size: 18px;
    font-weight: 800;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}
.Energy_fossil_fuels {
    /* width: 250px; */
    /* height: 23px; */
    margin-top: -23px;
    margin-left: 105px;
    font-family: SourceSansPro400;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}
.browse_by_category {
  /* width: 393px; */
  /* height: 242px; */
  margin-top: 30px;
  /* left: 947px; */
  border: 0.5px solid #c8c8c8;
}
.browsebycategory {
  width: 163px;
  height: 17px;
  margin-top: 20px;
  margin-left: 121px;
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  text-transform: uppercase;
}
.Eco_System {
  width: 106px;
  height: 23px;
  left: 985px;
  font-family: SourceSansPro400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.Eco_System li {
  font-family: SourceSansPro400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 5px;
  width: 146px;
  list-style-type: circle;
}
.para {
  width: 19px;
  height: 17px;
  top: 1531px;
  left: 1295px;
  opacity: 0.5;
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
  list-style-type: circle;
}
.blogsFooterimage1 {
  width: 288px;
  height: 235px;
  margin-top: 50px;
  margin-left: -12px;
}
.blogsFooterimage1Date {
  width: 94px;
  height: 17px;
  margin-top: 52px;
  margin-left: -12px;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
}
.blogsFooterimage1Energy {
  width: 52px;
  height: 17px;
  margin-top: -34px;
  margin-left: 103px;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  color: #174689;
}
.blogsFooterimage1heading {
  width: 497px;
  height: 56px;
  margin-left: -10px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.blogsFooterimage1para {
  width: 497px;
  height: 92px;
  margin-left: -10px;
  margin-top: 25px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
/* .blogsFooterimage1readmore {
  width: 98px;
  height: 17px;
  margin-top: -10px;
  margin-left: -13px;
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  color: #174689;
}
.blogsFooterimage1underline {
  width: 124px;
  margin-top: -10px;
  margin-left: -28px;
}

.blogsFooterimage2 {
  width: 288px;
  height: 235px;
  margin-top: 50px;
  margin-left: -12px;
}
.blogsFooterimage2Date {
  width: 94px;
  height: 17px;
  margin-top: 52px;
  margin-left: -12px;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
}
.blogsFooterimage2Energy {
  width: 52px;
  height: 17px;
  margin-top: -34px;
  margin-left: 103px;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  color: #174689;
}
.blogsFooterimage2heading {
  width: 497px;
  height: 56px;
  margin-left: -10px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.blogsFooterimage2para {
  width: 497px;
  height: 92px;
  margin-left: -10px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.blogsFooterimage2readmore {
  width: 98px;
  height: 17px;
  margin-top: -10px;
  margin-left: -13px;
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  color: #174689;
}
.blogsFooterimage2underline {
  width: 124px;
  margin-top: -10px;
  margin-left: -28px;
}

.blogsFooterimage3 {
  width: 288px;
  height: 235px;
  margin-top: 50px;
  margin-left: -12px;
}
.blogsFooterimage3Date {
  width: 94px;
  height: 17px;
  margin-top: 52px;
  margin-left: -12px;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
}
.blogsFooterimage3Energy {
  width: 52px;
  height: 17px;
  margin-top: -34px;
  margin-left: 103px;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  color: #174689;
}
.blogsFooterimage3heading {
  width: 497px;
  height: 56px;
  margin-left: -10px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.blogsFooterimage3para {
  width: 497px;
  height: 92px;
  margin-left: -10px;
  margin-top: 30px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.blogsFooterimage3readmore {
  width: 98px;
  height: 17px;
  margin-top: -32px;
  margin-left: -13px;
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  color: #174689;
}
.blogsFooterimage3underline {
  width: 124px;
  margin-top: -10px;
  margin-left: -28px;
}
.blogsFooterimage4 {
  width: 288px;
  height: 235px;
  margin-top: 50px;
  margin-left: -12px;
}
.blogsFooterimage4Date {
  width: 94px;
  height: 17px;
  margin-top: 52px;
  margin-left: -12px;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
}
.blogsFooterimage4Energy {
  width: 52px;
  height: 17px;
  margin-top: -34px;
  margin-left: 103px;
  font-family: SourceSansPro600;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  color: #174689;
}
.blogsFooterimage4heading {
  width: 497px;
  height: 56px;
  margin-left: -10px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.blogsFooterimage4para {
  width: 497px;
  height: 92px;
  margin-left: -10px;
  margin-top: -20px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.blogsFooterimage4readmore {
  width: 98px;
  height: 17px;
  margin-top: -10px;
  margin-left: -13px;
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  color: #174689;
}
.blogsFooterimage4underline {
  width: 124px;
  margin-top: -10px;
  margin-left: -28px;
} */
.footerextra {
  margin-top: 100px;
}
.portfolioBanner {
  width: 100%;
  height: 376px;
  margin-top: -15px;
}
.portfolioBannerHeading {
  margin-top: -250px;
  margin-left: 100px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 56px;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.portfolioBannerPara {
  margin-left: 100px;
  font-family: SourceSansPro400;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.Our_work {
      /* width: 93px; */
    /* height: 17px; */
    margin-top: 232px;
    /* margin-left: 683px; */
    font-family: SourceSansPro600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.175em;
    text-align: center;
    text-transform: uppercase;
}
.Our_work_underline {
  /* width: 74px; */
  margin-top: 19px;
  /* margin-left: 693px; */
  /* border: 1px solid #000000; */
}
.Explore_our_work {
    /* width: 310px; */
    /* height: 47px; */
    margin-top: 12px;
    /* margin-left: 574px; */
    font-family: AbhayaLibreExtraBold800;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
}
#poertfolioSection1container {
  margin-top: 50px;
}
.portfolioImage1 {
  width: 393px;
  height: 278px;
  top: 776px;
  left: 100px;
}
.portfolioImage2 {
  width: 120px;
  height: 117px;
  margin-top: 61px;
  margin-left: 212px;
}
.poertfolioImage3 {
  width: 287px;
  height: 278px;
  margin-left: -12px;
  background-color: #adcc49;
}
.United_States_NAVY {
     /* width: 265px; */
    /* height: 38px; */
    margin-top: 247px;
    font-family: AbhayaLibreExtraBold800;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
}
.United_States_NAVY_para {
    /* width: 291px; */
    /* height: 92px; */
    margin-top: 20px;
    font-family: SourceSansPro400;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}
.portfolioImage4 {
  width: 560px;
  height: 645px;
  margin-left: 9px;
}
#poertfolioSection2container {
  margin-top: 137px;
}
.portfolioimage5 {
  /* width: 605px;
  height: 428px; */
}
.portfolioImage6 {
  /* width: 154px;
  height: 84px; */
  margin-top: 25px;
}
.Eli_lilly_Company {
    /* width: 268px; */
    /* height: 39px; */
    margin-top: 148px;
    font-family: AbhayaLibreExtraBold800;
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
}
.Eli_lilly_Company_para {
    /* width: 393px; */
    /* height: 115px; */
    margin-top: 17px;
    font-family: SourceSansPro400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}
#poertfolioSection3container {
  margin-top: -103px;
}
.portfolioImage7 {
  width: 230px;
  height: 197px;
  margin-top: -94px;
  margin-left: 555px;
  background-color: #adcc49;
}
.portfolioImage8 { 
     /* width: 251px; */
  /* height: 274px; */
  margin-top: 100px;
  margin-left: 357px;
}

.portfolioImage9 {
  margin-left: 200px;
}
.General_Mills {
  /* width: 136px; */
  /* height: 28px; */
  margin-left: 114px;
  margin-top: 172px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: lefr;
}
.General_Mills_para {
  /* width: 393px; */
  /* height: 134px; */
  margin-left: 114px;
  margin-top: 6px;
  font-family: SourceSansPro400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.portfolioImage10 {
  /* width: 182px; */
  /* height: 64px; */
  margin-left: 114px;
  margin-top: 29px;
}
#poertfolioSection4container {
  margin-top: 129px;
}
.United_Airlines {
    /* width: 153px; */
    /* height: 28px; */
    margin-top: 83px;
    margin-left: 106px;
    font-family: AbhayaLibreExtraBold800;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0em;

}
.United_Airlines_para {
    /* width: 393px; */
    /* height: 184px; */
    margin-top: 5px;
    margin-left: 106px;
    font-family: SourceSansPro400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}
.portfolioImage12 {
  margin-left: 294px;
}
.portfolioImage13 {
  width: 182px;
  height: 75px;
  margin-top: 172px;
  margin-left: 191px;
}
.portfolioImage14 {
  margin-top: 113px;
}
.Indiana_University {
  margin-top: 46px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.Indiana_University_para {
  margin-top: 21px;
  font-family: SourceSansPro400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.portfolioImage15 {
  /* width: 605px;
  height: 560px; */
}
#Read_more {
  width: 98px;
  height: 37px;
  top: 3251px;
  font-family: SourceSansPro600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.175em;
  text-align: center;
  color: #174689;
  text-decoration: underline;
  border: none;
  background-color: #ffffff;
  text-transform: uppercase;
}
.minimizeDescription {
  width: 497px;
  height: 700px;
  opacity: 0.6;
  font-family: SourceSansPro400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}


.contactBanner {
  width: 100%;
  height: 376px;
  margin-top: -15px;
}

.contactUsHeading {
      /* width: 255px; */
    /* height: 66px; */
    margin-top: -250px;
    margin-left: 100px;
    font-family: AbhayaLibreExtraBold800;
    font-size: 56px;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.contactUsPara {
     /* width: 456px; */
    /* height: 46px; */
    margin-left: 100px;
    font-family: SourceSansPro400;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.ContactSection {
  /* background-color: grey; */
}
.contactBanner2 {
  width: 100%;
  height: 560px;
  margin-top: 115px;
}
.contactImage1 {
  width: 90px;
  height: 90px;
  top: 1030px;
  left: 285px;
  border: 6.75px;
  position: absolute;
}
.contactImage2 {
  width: 90px;
  height: 90px;
  top: 1030px;
  left: 1184px;
  border: 6.75px;
  position: absolute;
}

#contactContainer {
  margin-top: -520px;
  margin-left: -100px;
}
.contactInfoSocial {
  margin-top: 20px;
  margin-left: 20px;
  width: 300px;
  font-family: SourceSansPro400;
  font-size: 20px;
}
#contact_whatsapp_icon {
  margin-left: 0px;
}
#contact_facebook_icon {
  margin-left: 20px;
}
#contact_linkedin {
  margin-left: 20px;
}
#contact_telegram_ {
  margin-left: 20px;
}
.contactInfo {
  width: 461px;
  height: 500px;
  background-color: #ffffff;
  border: 0.5px solid #ffffff;
  opacity: 0.85;
}
.contactInfoHeading {
  width: 173px;
  height: 33px;

  margin-top: 20px;
  margin-left: 20px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 30px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.contactInfoAddress {
  width: 226.43px;
  height: 95px;
  margin-left: 20px;
  margin-top: 30px;
  font-family: SourceSansPro400;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.contactInfoEmail {
  width: 226.43px;
  height: 75px;
  margin-left: 20px;
  margin-top: 20px;
  font-family: SourceSansPro400;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.contactInfoPhoneNumber {
  width: 226.43px;
  height: 75px;

  margin-left: 20px;
  margin-top: 20px;
  font-family: SourceSansPro400;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
#Saysomething {
  width: 461px;
  height: 500px;
  margin-left: 10px;
  background-color: #ffffff;
  border: 0.5px solid #ffffff;
  opacity: 0.85;
}
.SaysomethingHeading {
  margin-top: 20px;
  margin-left: 10px;
  font-family: AbhayaLibreExtraBold800;
  font-size: 30px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.SaysomethingName {
  margin-left: 10px;
  margin-top: 20px;
  font-family: SourceSansPro600;
  font-size: 22px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
#SaysomethingName {
  margin-left: 10px;
  margin-top: 10px;
  font-family: SourceSansPro400;
  border-radius: 10px;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* border: 0.5 px #707070; */
}
.SaysomethingPhoneNumber {
  margin-left: 10px;
  margin-top: 20px;
  font-family: SourceSansPro600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
#SaysomethingPhoneNumber {
  margin-left: 10px;
  margin-top: 10px;
  font-family: SourceSansPro400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 10px;
}
.SaysomethingEmailAddress {
  margin-left: 10px;
  margin-top: 20px;
  font-family: SourceSansPro600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
#SaysomethingEmailAddress {
  margin-left: 10px;
  margin-top: 10px;
  font-family: SourceSansPro400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 10px;
}
.SaysomethingMessage {
  margin-left: 10px;
  margin-top: 20px;
  font-family: SourceSansPro600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
#SaysomethingMessage {
  margin-left: 10px;
  margin-top: 10px;
  width: 250px;
  height: 120px;
  font-family: SourceSansPro400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 10px;
}
.SaysomethingSubmit {
  background-color: #174689;
  color: #ffffff;
  border-color: #ffffff;
  width: 120px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: SourceSansPro600;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  margin-left: 130px;
}
.mapSection {
  margin-top: 320px;
  width: 100%;
  position: relative;
}
/* .location_map{
  width: 100%;
  height: 300px;
  margin-top: 100px;
} */
