@font-face {
    font-family: SourceSansPro200;
    src: url(./SourceSans3-ExtraLight.ttf);
  }

@font-face {
    font-family: SourceSansPro400;
    src: url(./SourceSans3-Regular.ttf);
  }
  
  @font-face {
    font-family: SourceSansPro500;
    src: url(./SourceSans3-Medium.ttf);
  }

  @font-face {
    font-family: SourceSansPro600;
    src: url(./SourceSans3-SemiBold.ttf);
  }
  @font-face {
    font-family: SourceSansPro700;
    src: url(./SourceSans3-Bold.ttf);
  }
  @font-face {
    font-family: AbhayaLibreExtraBold800;
    src: url(./AbhayaLibre-ExtraBold.ttf);
  }